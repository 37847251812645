import styled from "styled-components"

import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers"

import { TUser } from "src/data/user/user"
import { getPortalRoot } from "src/ui/BaseModalDialog/baseModalDialogUtils"
import ChevronDown from "src/ui/icons/chevron-down-larger.svg"
import { InputContainer } from "src/ui/InputContainer/InputContainer"
import { MText } from "src/ui/MText"
import { extractHHMM } from "src/utils/l10n"

type MTimePickerProps = {
  label?: string
  time: Date | null
  setTime: (t: Date | null) => void
  clockType: TUser["clock_type"]
  required?: boolean
}

export function MTimePicker({
  time,
  setTime,
  label,
  clockType,
  required,
}: MTimePickerProps) {
  const displayTime = time ? extractHHMM(time, clockType) : null
  return (
    // These internals are using a deprecated sub-dependency of an already
    // deprecated UI library; it should be updated to something better as soon
    // as reasonably possible.
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <InputContainer
        endAdornment={<ChevronDown width={16} color="unset" />}
        label={label}
      >
        <StyledMText>{displayTime}</StyledMText>

        <VisuallyHiddenMUITimePicker
          label={label}
          value={time}
          onChange={setTime}
          orientation="portrait"
          ampm={clockType === "12"}
          required={required}
          DialogProps={{
            container: getPortalRoot,
          }}
          PopoverProps={{
            container: getPortalRoot,
          }}

          // XXX: Keyboard events currently won't work; we could if needed, but
          // it's probably better to wait until we replace the MUI time picker
          // component.
        />
      </InputContainer>
    </MuiPickersUtilsProvider>
  )
}

const VisuallyHiddenMUITimePicker = styled(TimePicker)`
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
`

const StyledMText = styled(MText)`
  min-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
