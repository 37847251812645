import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { TMButtonProps } from "src/ui/Button/MButton"
import ChevronDown from "src/ui/icons/chevron-down-larger.svg"
import { InputContainer } from "src/ui/InputContainer/InputContainer"
import { MText } from "src/ui/MText"

export interface DateFieldDropdownProps extends TMButtonProps {
  onClick: () => void
  required?: boolean
  label?: string
  displayValue: string
}

const OPEN_KEYS = ["Enter", " ", "ArrowUp", "ArrowDown"]

export function DateFieldDropdown({
  onClick,
  label,
  required,
  displayValue,
}: DateFieldDropdownProps) {
  const { t, langKeys } = useTranslate()

  const dropdownLabel = label ?? t(langKeys.date)

  return (
    <InputContainer
      label={dropdownLabel}
      endAdornment={<ChevronDown width={16} color="unset" />}
    >
      <StyledMText>{displayValue}</StyledMText>
      <VisuallyHiddenInput
        name="time"
        value={displayValue}
        onClick={() => {
          onClick()
        }}
        onKeyDown={(e) => {
          if (OPEN_KEYS.includes(e.key)) {
            e.preventDefault()
            onClick()
          }
        }}
        required={required}
      />
    </InputContainer>
  )
}

const VisuallyHiddenInput = styled.input`
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
`

const StyledMText = styled(MText)`
  min-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
